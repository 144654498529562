import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  ninjaPlugin: null
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetch({ commit, state }) {
    try {
      const res = await this.$axios.$get(
        `${this.$config.apiUrlV2}/shipment/ninja-plugin`
      )
      commit('SET_NINJA_PLUGIN', res.data)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      // Create a new Error object with your custom properties
      const customError = new Error(
        error.response?.data?.message || 'API Error'
      )
      customError.code = error.response?.status
      customError.originalError = error
      throw customError
    }
  },

  async storeData({ commit, state }, { code, stateNinja }) {
    try {
      const res = await this.$axios.$post(
        `${this.$config.apiUrlV2}/shipment/ninja-plugin`,
        { code, state: stateNinja }
      )
      commit('SET_NINJA_PLUGIN', res.data)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async refresh({ commit, state }) {
    try {
      const res = await this.$axios.$patch(
        `${this.$config.apiUrlV2}/shipment/ninja-plugin`
      )
      commit('SET_NINJA_PLUGIN', res.data)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit, state }) {
    try {
      const res = await this.$axios.$delete(
        `${this.$config.apiUrlV2}/shipment/ninja-plugin`
      )
      commit('SET_NINJA_PLUGIN', null)
      return res.data
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
